#menuSideBarContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    .category{
        cursor: pointer;
    }
    button{
        width: 250px;
        height:80px;
        font-size:larger;
        cursor: pointer;
        font-weight: bold;
    }
    button:hover{
        background-color: lightgray;
    }
}


@media screen and (max-width: 600px){
    #menuSideBarContainer{
        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 16px;
        margin-top: 16px;
        .category{
            cursor: pointer;
            width: auto;
            
        }

        button{
            width: auto;
            height:50px;
            flex-wrap: wrap;
            font-size:medium;
            display: flex;
            justify-content: center;
            align-items: center;
            color:black; 
            font-weight: bold;  
            padding: px;  
        }
        button:hover{
            background-color: lightgray;
        }
    }
   
}   