#order-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    .order-sub-container{
        background-color: rgb(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 70%;
        border-radius: 30px;
        text-align: center;
        .title-box{
            text-align: center;
        }
       

        .order-details{
            margin: 10px; 
            display: flex;  
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        .btn-div{
            display: flex;
        }
    }
}

@media screen and (max-width: 600px){

    .qc{
        width:  140px;
    }
}
