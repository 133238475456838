#loginContentContainer{
    display:flex;
    justify-content: center;

    #loginContentBox{
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255, 0.8);
        padding: 30px;
        border-radius: 20px;
        justify-content: center;
    }
    form{
        display: flex;
        flex-direction: column;
        width: 250px;
    }

    label{
        margin: 5px;
    }
    
    #space{
        min-height: 20px;
    }
    button{
        text-decoration-line: none;
        border-radius: 20px;
        cursor: pointer;
        background-color: lightgray;
        width: 150px;
        height: 50px;
        color:black;
        font-weight: normal;
    }
    button:hover{
        background-color: gray;
        transition:  0.3s;
        font-weight: bold;
    }
    input{
        height: 30px;
    }
    .error{
        color:red;
        margin-top: 20px;
    }
}