

#homeContainer{
    color: white;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight:bolder;

    .nav-button{
        padding: 10px 20px;
        background-color: gray;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        transition:  0.3s;
        text-decoration: none;
        width: 150px;
        height: 50px;
        font-size: large;
        text-decoration: none;

    }

    .nav-button:hover{
        background-color: rgb(125, 117, 117);
        font-weight:bolder ;
        transition:  0.3s;
        color:bisque;
    }
}







@media screen and (max-width: 600px){

    #homeTitle{
        margin: 10px;
        
    }
}