#confirmation-container{
    background-color: rgb(255, 255, 255, 0.8);
    color: black;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    width: auto;
    height: 100%;

    .invalid-details{
        font-size: 16px;
        color: red;
        padding-left: 10px;
        margin-bottom: 10px;
    }


    #confirmation-sub-container{
        display: flex;
        flex-direction: column;

        #title-container{
            display: flex;
            justify-content: center;
            font-size:x-large;
            padding: 30px;
            font-weight: bold;
        }
        .header-content{
            display: flex;
            justify-content:space-between;
            border-bottom: 1px solid gray;
            padding-bottom: 4px;
            margin: 0px 10px;
            
            .item-label{
                width: 100%;
                font-size: large;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .wrapper{
            width: 100%;
            height: 100%;
            white-space: nowrap;
            overflow: hidden;

        }
        .wrapper-name{
            width: 100%;
            height: 100%;
            white-space: nowrap;
            overflow: hidden;
            min-width: 90px;
        }
        
        #items-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 0px 10px;

            .cart-items{
                display: flex;
                border-bottom: 1px solid gray;
                padding: 10px 0px;
                justify-content:space-between;
                align-items: center;
                .image{
                    width: 50px;
                    height: 50px;
                    border: 1px solid green;
                    border-radius: 10px;
                }
            }
            .item-detail{
                display: flex;
                flex-direction: column;

                .item-name{
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
        }
        .total-container{
            padding: 12px;
        }

        .confirm-btn{
            .back-to-cart{
                background: none;
                height: 40px;
                margin-left: 4px;
                color:blue;
                border: none;
                display: flex;
                justify-content: start;
                cursor: pointer;
            }
            .back-to-cart:hover{
                color: purple;
                transition:  0.3s;
            }

            .confirm{
                display:flex;
                justify-content: center;
                align-content: center;
                padding: 0 0 50px 0;

                .confirm-order{
                    align-self:center;
                    margin: 60px 0;
                    border-radius: 10px;
                    font-weight: bold;
                    transition:  0.3s;
                    width: 130px;
                    height: 50px;
                    font-size: normal;
                    text-decoration: none;
                }
                .confirm-order:hover{
                    background-color: lightgrey;
                    transition:  0.3s;
                }
            }

        }
    }
}