#menuItemContainer{
    color: black;
    border: 2px solid gray;
    width: 250px;
    height: 370px;
    background-color: azure;
    display: flex;
    flex-direction: column;
    padding: 4px;

}
#itemImage{
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 10px;
    border: 1px solid darkgray;
    
    img{ 
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    cursor: pointer;
}

#itemDetail{
    display: flex;
    justify-content: space-between;
   
}

#details{
    padding:0 0 0 4px;
    display: flex;
    flex-direction: column;
    width: 200px;

    #title, #description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 24px;
        
    }
}

#icon{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    margin-left: 8px;
    padding: 20px 0px;
    width: 40px;
    .star{
        cursor: pointer;
    }
    .addToCart{
        cursor: pointer;
        transition:  0.3s;
    }
    .addToCart:hover{
        color: brown;
        transition:  0.3s;
    }
    .update-button{
        cursor: pointer;
        transition:  0.3s;
    }
    .update-button:hover{
        color: brown;
        transition:  0.3s;
    }
}