.drop-down{
    margin: 30px;
    position: relative;
    display: inline-block;
    z-index: 1000; 
    .icon{
        font-size: 70px;
        cursor: pointer;
        color: white;
        transition: 0.3s;
    }
    .icon:hover{
        color: lightgrey;
        transition: 0.3s;
    }

    .dropdown-items{
        margin: 10px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: none;
        border: none;
        width: 200px;   
        color: black;
       
       
    }

    button{
        padding: 10px;
        cursor: pointer;
        text-align: left;
        width: 100%;
        font-weight: bolder;
        color: black;
        height: 70px;
        font-size: larger;
      
       
        
    }
    button:hover{
        background-color: lightgrey;
    }
}