#menuContainer{
    position: relative;
    display: flex;
    justify-items: start;
    background-color: rgb(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 20px;
   

    #header{
        font-weight: bold;
        font-size: 24px;
        height: 40px;
        margin-bottom: 8px;
        border-bottom: 1px solid gray;
        display: flex;
        justify-content: center;
    }



    #itemBox{
        width: 100%;
        #titleAndSearchBox{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid gray;
            margin-left: 20px;
            padding-bottom: 4px;
            margin-bottom: 8px;

            .title{
                font-size: 24px;
                font-weight: bold;
                color: black;
            }
            #searchAndCartIcon{
                display: flex;
            }
            #cartIcon{
                all: unset;
                margin-left: 24px;
                cursor: pointer;
                transition:  0.3s;
            }
            #cartIcon:hover{
                color: brown;
                transition:  0.3s;
            }
            .searchBar{
                input[type="text"]{
                    font-size: 18px;
                    border: none;
                    height: 32px;
                    border-radius: 4px;
                    padding-left: 8px;
                }

                button{
                    cursor: pointer;
                    width: 90px;
                    height: 32px;
                    margin-left: 8px;
                    color: black;
                }
                button:hover{
                    transition:  0.3s;
                }
            } 
        }
        .items{
            margin-left: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            width: auto;
            gap: 10px;
        }    
        
    }
    #itemDetail-popup{
        z-index: 1000; 
        position: absolute;
        right: 0;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        justify-content: center;
        display: flex; 
        
    }
}


@media screen and (max-width: 600px){
    #menuContainer{
        display: flex;
        flex-direction: column;
       

        #titleAndSearchBox{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title{
            font-size: 24px;
            font-weight: bold;
            color: black;
            margin-bottom: 16px;
        }

        #searchAndCartIcon{
            margin-bottom: 8px;            
        }
    }
}




