@media screen and (min-width: 601px){

    header{
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10rem;
        width: 100%;
        
        
        #navBox{
           
            display: flex;
            align-items: center;
            justify-content: center;
            .navLink {
                text-decoration: none;
                cursor: pointer;
                color: white;
                font-size:x-large;
                margin-right: 60px;
                font-weight: bold;
                transition: 0.3s;
            }
            .navLink:hover{
                color: lightgrey;
                transition: 0.3s;
            }
        }
        
        

        img{
            border: 2px solid goldenrod;
            border-radius: 100%;
            height: 80px;
            width:80px;
            margin-left: 30px;
            
        }
      
    }
}



@media screen and (max-width: 600px){
    header{
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center; 
        width: 100%;
        height: 40%;
        flex-wrap: wrap;

        img{
            border: 2px solid goldenrod;
            border-radius: 100%;
            height: 80px;
            width:80px;
            margin: 10px;
            
        }
    }
}
