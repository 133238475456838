

#ItemBigBox{
    border: 1px solid gray;
    background-color: azure;   
    max-width: 600px; 
    width: 100%;
    display: flex;
    flex-direction: column;

    #imageCloseContainer{
        position: relative;
        min-width: 260px;
        min-height: 180px;
        width: fit-content;   
        
        
        .IconButton{
            position: absolute;
            top:0;
            right:0;
            height: 40px;
            width: 40px;
            margin: 10px;
            color:rgb(215, 39, 39);
            font-weight: bolder;   
        }
        .image-container{
            min-width: 250px;
            min-height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            box-sizing: border-box;
            padding: 4px;
            border-radius: 10px;
            img{ 
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }

    #detail-container{
        display: flex;
        margin-left: 30px;
        padding: 10px;
        justify-content: space-between;

        .cart-icon{
            cursor: pointer;
        }
        .cart-icon:hover{
            color:brown
        }
        .start-icon{
            cursor: pointer;
        }
      
    }
}








