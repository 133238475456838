
#content-main-box{
    
    background-color: rgb(255, 255, 255, 0.8);
    padding-bottom: 40px;
    position: relative;

    #content-sub-box{
        display: flex;
        flex-direction:column;
        flex-wrap: wrap;
        .head-box{
            display: flex;
            justify-content: center;       
        }
        .cart-box{
            display: flex;
            justify-content: flex-end;
            margin-right: 20px;
            #cartIcon{
                all: unset;
                cursor: pointer;
                transition:  0.3s;
            }
            #cartIcon:hover{
                color: brown;
                transition:  0.3s;
                
            }
            
        }
        
    }

    .items{
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: auto;
        gap: 10px;
    }  

    #itemDetail-popup{
        z-index: 1000; 
        position: absolute;
        top: 0;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex; 
    }

    
}