#signupContentContainer{
    display: flex;
    justify-content: center;

    #signupContentBox{
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255, 0.8);
        padding: 30px;
        border-radius: 20px;
        flex-wrap: wrap;
        width: auto;
    }

    form{
        display: flex;
        flex-direction: column;
        width: 250px;
        #birthday{
            color:black
        }  
    }

    label{
        margin: 5px;
    }

    #spacer{
        min-height: 20px;
    }
    button{
        background-color: lightgrey;
        border-radius: 20px;
        cursor: pointer;
        color: black;
        width: 150px;
        height: 50px;
        text-decoration-line: none;
        
    }
    button:hover{
        background-color: gray;
        transition:  0.3s;
        font-weight: bold;
      
    }
    input{
        height: 30px;
        
    }


    .confirmation {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .confirmation h2 {
        margin-bottom: 30px;
        
    
    }
    
    .confirmation a {
        color: rgb(78, 73, 73);
        text-decoration: none;
        font-weight: bold;
        border: 1px solid black;
        padding: 10px;
        background-color: lightgrey;
        border-radius: 10px;
        color: black;
        width: 120px;
        height: 50px;
        

    }
    .confirmation a:hover{
        background-color: gray;
    }
    .error{
        color:red;
        margin-top: 20px;
    }
}

