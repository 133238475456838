#admin-container{
   
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255, 0.8);
    #admin-sub-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 30px;
        
        
        .nav-btn{
            width: 200px;
            height: 70px;
            border-radius: 5px;
            margin: 30px;
            color: black;
            background-color: lightgray;
            transition:  0.3s;
            font-size: large;
        }
        .nav-btn:hover{
            background-color: gray;
            font-weight: bold;
            color: black;
            transition:  0.3s;
        }
    
    }
}

