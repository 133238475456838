.order-history {
  padding: 20px;
  background-color: rgb(255, 255, 255, 0.8);
}

.historytitle {
  margin-bottom: 20px;
}

.order-list {
  display: flex;
  flex-direction: column;
}

.order-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: white;
}

.items-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom: 1px solid gray;
  padding-bottom: 4px;
}

.item-details {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding-bottom: 4px;
}

.item-name-content{
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 4px 0px;
}

.item-content{
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 4px 0px;
}



.order-details-left {
  flex: 1;
  justify-content: space-between;
}


.order-details-right {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  padding-top: 10px;
}

.order-details-right p {
  margin: 0;
}

.top-container{
  display: flex;
    .status-change-right{
      button{
        margin: 10px;
        width: 200px;
        height: 50px;
        border-radius: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button:hover{
        background-color: gray;
        font-weight: bold;
      }
    }
}
